<template>
  <div>
    <PageTitle title="线上咨询"
               fixed
               :backTo="'/doctors/' + $route.params.departmentId"></PageTitle>

    <div style="width: 85%;margin: 15px auto;">
      <List :width="100">
        <ListItem class="doctorInfo"
                  :to="'/doctorInfo/' + doctorInfo.jobnumber + '/' + doctorInfo.departmentid"
                  @click="saveFormData"
                  :leftWidth="20"
                  :rightWidth="80">
          <template #left>
            <img :src="doctorInfo.profile_photo || defaultDoctorPicture" />
          </template>
          <template #right>
            <p>{{ doctorInfo.doctorname }}<span>{{ doctorInfo.department }}/{{ doctorInfo.area }}</span></p>
          </template>
        </ListItem>
        <ListItem class="cardInfo">
          <template #top>
            <p><span>就诊卡：{{ cardInfo.brxm }}</span><span>{{ cardInfo.xzmc }}</span><span>{{ cardInfo.ckhm | filterCardNum }}</span><span @click="saveFormData">
                <router-link :to="'/accountJudgment/0/' + $route.params.departmentId + '/' + $route.params.doctorId + '?backPath=consultOnline'">
                  <van-icon name="idcard"
                            size="16px" />卡包
                </router-link>
              </span></p>
          </template>
        </ListItem>

        <ListItem>
          <template #top>
            <van-form>
              <p>病情描述</p>
              <van-field v-model="formData.diseaseDescrip"
                         placeholder="请尽可能详细地描述您的病情，如发病时间、既往病史（可添加检验检查报告单），以便医生更准确治疗。"
                         type="textarea"
                         autosize
                         right-icon="edit"
                         maxlength="500" />
              <van-uploader v-model="formData.pictrueList"
                            :after-read="afterRead">
                <van-field placeholder="您可以拍照上传照片，帮助描述病情"
                           type="textarea"
                           right-icon="add-o" />
              </van-uploader>
              <p style="margin-top: 10px;">门诊时间</p>
              <van-field v-model="formData.date"
                         @click="calendarShow = true"
                         readonly
                         is-link
                         arrow-direction="down"
                         input-align="center" />
              <van-calendar :default-date="new Date(formData.appointTime)"
                            v-model="calendarShow"
                            @confirm="confirmDate"
                            :show-confirm="false"
                            :color="variables.green" />
            </van-form>
          </template>
        </ListItem>
      </List>
      <van-button type="primary"
                  block
                  @click="submit()">提交</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';
import variables from '@/style/var.less';

import { getDoctorInfo, uploadFiles, checkApplyRepeat } from '@/api/hospital.js';
import { getDefaultAccount } from '@/api/user.js';

export default {
  components: {
    PageTitle,
    List,
    ListItem
  },
  data () {
    return {
      defaultDoctorPicture: require('../assets/default_doctor.png'),
      variables: variables,
      doctorInfo: {
        area: "",
        clinic: "",
        department: "",
        departmentid: "",
        description: "",
        doctorid: 0,
        doctorname: "",
        doctorskill: null,
        introdution: null,
        jobnumber: "",
      },
      cardInfo: {
        address: null,
        birthday: null,
        brxb: "",
        brxm: "",
        brxz: "",
        ckhm: "",
        id: 0,
        ktye: 0,
        mrzh: null,
        mz: null,
        namepb: null,
        nl: null,
        phonePB: null,
        sfzh: "",
        sfzpb: null,
        sjhm: null,
        srbrxm: null,
        srphone: null,
        srsfzh: null,
        xzmc: "",
        zhye: 0,
        zhyxpb: null
      },
      formData: {
        date: this.$util.moment().format('M月DD日'),
        pictrueList: [],
        "appointTime": this.$util.moment().format('YYYY-MM-DD'),
        "diseaseDescrip": "",
        "imgUrl": "",
        "doctorDepartmentID": this.$route.params.departmentId,
        "doctorJobnumber": 0,
        "patientID": "",
        "clinicID": "",
        "cardID": "",
        "openID": this.$store.getters.openId,
        "sjr_Name": "",
        "sjr_Phone": "",
        "sjr_Province": "",
        "sjr_City": "",
        "sjr_Area": "",
        "sjr_Street": "",
        "sjr_SpecificAddress": ""
      },
      calendarShow: false,
    }
  },
  methods: {
    afterRead (files) {
      // 此时可以自行将文件上传至服务器
      uploadFiles(files).then(
        (data) => {
          if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
              files[i].url = data[i];
              delete files[i].content;
              delete files[i].file;
              delete files[i].message;
              delete files[i].status;
            }
          } else {
            files.url = data[0];
            delete files.content;
            delete files.file;
            delete files.message;
            delete files.status;
          }
        }, () => { }
      )
    },
    confirmDate (value) {
      this.$set(this.formData, 'date', this.$util.moment(value).format('M月DD日'));
      this.$set(this.formData, 'appointTime', this.$util.moment(value).format('YYYY-MM-DD'));
      this.calendarShow = false;
    },
    getImagesUrl () {
      let imagesUrl = [];
      for (let picture of this.formData.pictrueList) {
        picture.url && imagesUrl.push(picture.url);
      }
      return imagesUrl.join('|');
    },
    saveFormData () {
      this.formData.imgUrl = this.getImagesUrl();
      this.formData.diseaseDescrip = this.formData.diseaseDescrip.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
      this.$store.dispatch('pageData/setData', this.formData);
      localStorage.setItem('pageTempData', JSON.stringify(this.formData));
    },
    submit () {
      if (this.cardInfo.zhyxpb == '0') {
        this.$dialog.alert({
          title: '温馨提醒',
          message: '此卡已失效，请更换卡号',
        }).then(() => { });
        return;
      }
      if (this.cardInfo.brxz === '27') {
        this.$dialog.alert({
          title: '温馨提醒',
          message: '全国异地医保患者无法使用云医院复诊功能',
        }).then(() => { });
        return;
      }

      this.saveFormData();

      function submitJudge (vue) {
        if (vue.cardInfo.zhye < 800) {
          vue.$dialog.confirm({
            title: '温馨提醒',
            message: '您当前账户预存金额为' + vue.cardInfo.zhye + '元\n为了您的云申请单顺利处理\n请保持账户预存金额大于800元\n（此金额随时可以退回）',
            confirmButtonText: '跳过',
            cancelButtonText: '充值'
          }).then(() => {
            // on confirm
            vue.$dialog.alert({
              title: '温馨提醒',
              message: '您的云门诊申请单正在提交中\n后期我们可提供快递送药服务\n请先选择您的取药方式\n（可以选择来院自取/快递寄出）',
            }).then(() => {
              // on close
              vue.$router.push('/address/' + vue.$route.params.departmentId + '/' + vue.$route.params.doctorId);
            });
          }).catch(() => {
            // on cancel
            localStorage.setItem('payDepartmentId', vue.$route.params.departmentId);
            localStorage.setItem('payDoctorId', vue.$route.params.doctorId);
            localStorage.setItem('payCardId', vue.cardInfo.ckhm);
            localStorage.setItem('payPreRoute', 'consultOnline');
            vue.$router.push('/recharge/1?backPath=consultOnline');
          });
        } else {
          vue.$dialog.alert({
            title: '温馨提醒',
            message: '您的云门诊申请单正在提交中\n后期我们可提供快递送药服务\n请先选择您的取药方式\n（可以选择来院自取/快递寄出）',
          }).then(() => {
            // on close
            vue.$router.push('/address/' + vue.$route.params.departmentId + '/' + vue.$route.params.doctorId);
          });
        }
      }

      checkApplyRepeat(this.formData.appointTime, this.formData.cardID, this.$route.params.departmentId, '').then((result) => {
        if (result.length) {
          this.$dialog.confirm({
            title: '温馨提醒',
            message: result[0],
            confirmButtonText: '修改时间',
            cancelButtonText: '继续'
          }).then(() => {
            // on confirm
            this.calendarShow = true;
          }).catch(() => {
            // on cancel
            //判断金额
            submitJudge(this);
          });
          return;
        }

        submitJudge(this);
      }, () => { });
    }
  },
  beforeCreate () {
    getDoctorInfo(this.$route.params.doctorId, this.$route.params.departmentId).then(
      (data) => {
        this.doctorInfo = data;
        this.formData.doctorJobnumber = this.doctorInfo.jobnumber;
      }, () => { });
  },
  created () {
    if (this.$route.params.pageStatus == 0) {
      localStorage.setItem('pageTempData', '');
      //表单赋值
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
        (data) => {
          if (data) {
            this.cardInfo = data;
            this.formData.cardID = this.cardInfo.ckhm;
            this.formData.clinicID = this.cardInfo.mzhm;
            this.formData.patientID = this.cardInfo.patientId.toString();
            this.$store.dispatch('pageData/setInfo', data);

            if (data.zhyxpb == '0') {
              this.$dialog.alert({
                title: '温馨提醒',
                message: '此卡已失效，请更换卡号',
              }).then(() => { });
            }
          } else {
            this.$dialog.alert({
              title: '温馨提醒',
              message: '未查找到默认账户信息，请先设置',
            }).then(() => {
              this.$router.push('/accountJudgment/0/' + this.$route.params.departmentId + '/' + this.$route.params.doctorId + '?backPath=consultOnline');
            });
          }
        }, () => {
          this.$dialog.alert({
            title: '温馨提醒',
            message: '未查找到默认账户信息，请先设置',
          }).then(() => {
            this.$router.push('/accountJudgment/0/' + this.$route.params.departmentId + '/' + this.$route.params.doctorId + '?backPath=consultOnline');
          });
        });
      return;
    }
    if (this.$route.params.pageStatus == 1) {
      //表单数据回填
      if (localStorage.getItem('pageTempData')) {
        this.formData = JSON.parse(localStorage.getItem('pageTempData'));
      }

      if (this.$store.getters.cardInfo) {
        this.cardInfo = this.$store.getters.cardInfo;
        this.formData.cardID = this.cardInfo.ckhm;
        this.formData.clinicID = this.cardInfo.mzhm;
        this.formData.patientID = this.cardInfo.patientId.toString();
      }
      return;
    }
    if (this.$route.params.pageStatus == 2) {
      this.$dialog.alert({
        title: '温馨提醒',
        message: '您的云门诊申请单提交成功',
      }).then(() => {
        // on close
        this.$router.push('/');
      });
      return;
    }
    if (this.$route.params.pageStatus == 3) {
      //表单赋值
      this.formData = this.$store.getters.temp;
      //清空页面临时数据
      this.$store.dispatch('pageData/clearData');
      localStorage.setItem('pageTempData', '');

      //错误消息提醒
      this.$dialog.alert({
        title: '温馨提醒',
        message: '云门诊申请单提交失败，' + this.$store.getters.temp.errMsg,
      }).then(() => { });
    }
  }
}
</script>

<style scoped="" lang="less">
@import "../style/var.less";

.doctorInfo {
  vertical-align: middle;

  .left img {
    height: 24px;
    border-radius: 50px;
  }
  .right span {
    font-size: @font-size-sm;
  }
}

.cardInfo {
  font-size: @font-size-sm;

  span {
    margin: 3px;

    a {
      color: @green;
    }
  }

  .van-icon {
    position: relative;
    top: 4px;
  }
}

.infoContainer {
  padding: 10px 0;

  p {
    margin: 0;
  }

  .top {
    p {
      text-align: center;
    }

    .van-uploader {
      width: 100%;
    }

    .van-popup {
      height: 65vh;
    }
  }
}
</style>
